@import 'variables';


.modal_window {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  opacity: 1;
  transition: opacity .4s;
  justify-content: center;
  align-items: center;
  background: rgba(black, 0.5);

  .modal {
    background: white;
    position: relative;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 70px;

    .modal_row {
      display: flex;
      width: 100%;
      flex-direction: column;
      margin: 20px 0;

      h5 {
        color: rgba(0,0,0,0.8);
        margin-bottom: 10px;
      }
      input {
        border: none;
        border-bottom: 1px solid rgba(black, 0.3);
      }
      input:focus{
        outline: none;
        border-bottom: 1px solid rgba(black, 0.8);
      }
      input.incorrect {
        border-bottom: 1px solid #dc3545;
      }
    }

    button {
      margin: 50px 0;
    }

    .close_btn {
      position: absolute;
      top: 15px;
      right: 15px;
      margin: 0;
      font-size: 3rem;
      width: 4rem;
      height: 4rem;
      padding: 0;
      background: none;
      color: rgba(0,0,0,0.8)
    }

    .close_btn:hover {
      background: rgba(0,0,0,0.1);
    }
  }
}

.modal_window.hidden {
  opacity: 0;
  visibility: hidden;
}