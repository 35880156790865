$global_width: 80vw;
$global_width_wide: 60vw;
$header_height: 8vh;
$subheader_height: $header_height*0.8;
$content_height: 100vh - $header_height*2;
$main_good_height: 80vh - $header_height;
$good_height: 100vh - $header_height*2.5;
$text_color: #5f5f79;
$button_color: #0071e3;
$appear: appear;


@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media screen and (min-width: 2000px) {
  $global_width: 60vw;
}