@import '../variables';
@import 'overview';
@import 'specs';
@import 'buy';


.subnavbar {
  display: flex;
  width: $global_width;
  margin: 0 auto;
  height: $subheader_height;
  align-items: center;
  transition: all .3s;

  h4 {
    font-weight: 600;
  }

  h3 {
    flex: 1;
  }

  ul {
    display: flex;
    flex: 1;
    justify-content: flex-end;

    li {
      padding: 5px 15px;
      font-size: 1.8rem;
      cursor: pointer;
      transition: all .2s;
    }

    li:hover {
      color: $button_color;
      opacity: 0.8;
    }

    .active {
      color: $button_color;
      opacity: 0.8;
    }

    .buy {
      padding: 5px 20px;
      margin-left: 15px;
      color: white;
      background: $button_color;
      border-radius: 3.5rem;
    }

    .buy:hover {
      color: white;
    }

    .buy.active {
      color: white;
      opacity: 1;
    }
  }
}

.subnavbar.scrolled {
  position: sticky;
  top:0;
  background: rgba(white, 0.9);
  z-index: 10;
  padding: 0 10px;
}

.good_subpage {
  min-height: $good_height;
  width: $global_width;
  margin: 0 auto;
}