@import '../variables';


.specs_container {
  width: 100%;
  margin: 100px 0;
  display: flex;
  align-items: center;
  flex-direction: column;

  h2 {
    margin-bottom: 100px;
  }

  .specs_row.last {
    border-bottom: none;
  }

  .specs_row {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 40px 20px;
    border-bottom: 1px solid rgba(0,0,0,0.2);

    h4 {
      flex: 1;
      padding-right: 10px;
    }

    .specs_details {
      flex: 3;
      display: flex;
      padding-left: 10px;
      p {
        flex: 1;
        padding: 0 20px;
      }
    }
  }
}