@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');
@import 'variables';
@import 'header';
@import 'parallax';
@import 'main_page';
@import 'goodpage/good_page';
@import 'cart';
@import 'notificator';
@import 'modal';
@import 'footer';

body {
  overflow-x: hidden;
  overflow-y: hidden;
}

* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}

h1 {
  font-size: 6rem;
  font-weight: 600;
}

h2 {
  font-size: 5rem;
  font-weight: 500;
}

h3 {
  font-weight: 500;
  font-size: 4rem
}

h4 {
  font-weight: 500;
  font-size: 2.5rem;
}

h5 {
  font-weight: 400;
  font-size: 2rem;
}

p {
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 400;
}

b {
  font-size: 1.5rem;
}

ul {
  list-style: none;
  li {
    font-size: 1.5rem;
  }
}

input {
  font-size: 2rem;
  background: none;
}

button {
  background: none;
  background: $button_color;
  border: none;
  color: white;
  border-radius: 1rem;
  padding: 1rem 2.5rem;
  font-size: 1.6rem;
  opacity: 1;
  cursor: pointer;
  transition: all .2s;
}

button:hover {
  opacity: 0.8;
}

.main_loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: appear 1s ease;
}

img {
  opacity: 1;
  transition: opacity .5s;
}

img.hidden {
  opacity: 0;
}

@import 'mobile';