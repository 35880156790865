@import "variables";

.main_goods {
  margin-top: 100px;
  min-height: $good_height;
}

.main_good_container {
  display: flex;
  width: $global_width;
  min-height: $main_good_height;
  margin: 0 auto;
  align-items: center;

  .main_good_description {
    flex: 1;
    height: 100%;
    display: flex;
    align-items: center;

    .desc_container {
      h2 {
        margin: 20px 0;
      }

      ul {
        li {
          padding: 3px 0;
        }
      }

      .desc-buttons {
        display: flex;
        button {
          margin: 3rem 1rem 0 0;
          border: none;
          background: none;
          color: $button_color;
        }

        .buy {
          color: white;
          background: $button_color;
          opacity: 1;
          border-radius: 4.8rem;
        }

        .buy:hover {
          opacity: 0.8;
        }
      }
    }
  }

  .main_good_image {
    display: flex;
    justify-self: center;
    justify-content: flex-start;
    height: 100%;
    flex: 1;

    img {
      object-fit: contain;
      max-width: min(90%, 650px);
    }
  }

  .right {
    justify-content: flex-end;
  }
}