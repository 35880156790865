@import 'variables';

.notificator {
  position: fixed;
  top: 20vh;
  left: 50vw;
  transform: translate(-50%,-50%);
  padding: 5px 40px 10px;
  background: white;
  color: $button_color;
  font-weight: 400;
  box-shadow: 2px 3px 5px rgba(black,0.4);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  opacity: 1;
  z-index: 0;
  animation: showN .4s ease;
}

.notificator.hidden {
  opacity: 0;
  z-index: -1;
  animation: hiddenN .4s ease;
}

@keyframes showN {
  0% {
    opacity: 0;
    z-index: -1;
  }
  1% {
    z-index: 0;
    opacity: 0.01;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hiddenN {
  0% {
    opacity: 1;
  }
  99% {
    z-index: 0;
    opacity: 0.01;
  }
  100% {
    z-index: -1;
    opacity: 0;
  }
}