@import "variables";

footer {
  background: rgb(50,50,50);
  height: $header_height;
  padding: 0 calc((100vw - #{$global_width})/2);
  display: flex;
  align-items: center;
  justify-content: space-between;

  div {
    flex: 1;
    display: flex;
    color: white;
    align-items: center;
    justify-content: flex-start;
    img {
      max-height: $header_height/2;
      transform: translateY(-3px);
      margin-right: 5px;
    }
  }
  ul {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    li {
      margin-left: 30px;
    }
  }
}

footer.safari {
  display: flex;
}

