@import 'variables';

header {
  display: flex;
  width: $global_width;
  margin: 0 auto;
  height: $header_height;
  align-items: center;

  .burger_container {
    display: none;
    position: relative;
    height: 100%;
    flex: 1;

    .burger_button {
      height: 100%;
      display: flex;
      align-items: center;

      img {
        max-height: $header_height*0.5;
        cursor: pointer;
        background: white;
        border-radius: 50%;
        padding: 5px;
      }

      transition: all .3s;
      z-index: 1;
    }

    .burger_nav {
      position: absolute;
      z-index: 10;
      transition: all .3s;
      background: rgba(white,0.9);
      border-radius: 0 0 10px 10px;

      ul {
        padding: 15px 20px 15px 0;

        li {
          padding: 10px 10px 10px 0;
          font-size: 2.5rem;
          cursor: pointer;
          border-bottom: 1px solid rgba(black, 0.1);
          transition: all .2s;
        }

        li:hover {
          color: rgba($button_color, 0.8);
          border-bottom: 1px solid rgba($button_color, 0.8);
        }

        li.selected {
          color: rgba($button_color, 0.8);
          border-bottom: 1px solid rgba($button_color, 0.8);
        }
      }
    }

    .burger_nav.hidden {
      transform: scaleY(0) translateY(-100%);
      opacity: 0;
    }
  }


  .logo_container {
    flex: 1;
    display: flex;

    img {
      max-height: $header_height*0.5;
      opacity: 0.8;
      transform: translateY(-0.5vh);
      cursor: pointer;
    }
  }

  nav {
    flex: 2;

    ul {
      display: flex;
      justify-content: space-around;

      li {
        list-style-type: none;
        font-size: 2.5rem;
        margin: 0 10px;
        cursor: pointer;
        transition: color .3s;
      }

      li:hover {
        color: rgba($button_color, 0.8)
      }

      li.selected {
        color: rgba($button_color, 0.8)
      }
    }
  }

  .nav_cart_wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .nav_cart {
      cursor: pointer;
      display: block;
      position: relative;
      transform: translateY(-$header_height*0.1);

      img {
        height: $header_height*0.5;
        object-fit: contain;
      }

      .count {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -70%);
        font-size: 2rem;
        font-weight: 600;
        color: $button_color;
      }
    }
  }
}




