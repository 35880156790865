@import 'variables';

.cart_wrapper {
  min-height: $content_height;
  width: $global_width;
  margin: 0 auto;
  padding: 100px 0;
  display: flex;
  flex-direction: column;

  .cart {
    width: 100%;
    margin: 40px 0;
    display: flex;
    flex-direction: column;

    .cart_item.last {
      border-bottom: none;
    }

    .cart_item {
      display: flex;
      align-items: center;
      width: 100%;
      height: 300px;
      padding: 25px 0 20px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);

      .cart_item_image {
        flex: 1;
        padding-right: 40px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        height: 100%;

        img {
          object-fit: contain;
          max-height: 90%;
          width: 90%;
        }
      }

      .cart_item_desc_container {
        flex: 2;
        width: 100%;
        height: 100%;
        padding-left: 20px;
        display: flex;
        justify-content: space-between;

        .desc {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          ul {
            li {
              display: flex;

              h4 {
                font-weight: 500;
              }

              h5 {
                padding: 10px 0;
              }

              p {
                padding: 7px 0;
                line-height: 2rem;
              }

              .title {
                color: rgba(0, 0, 0, 0.7);
                margin-right: 5px
              }
            }
          }
        }

        .quantity {
          flex: 1;
          text-align: center;

          select {
            width: 6rem;
            option {
              font-size: 3rem;
            }
            font-weight: 500;
            font-size: 3rem;
            border: none;
            background: none;
          }
        }

        .price {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;

          h3 {
            font-size: 3rem;
          }

          button {
            border: none;
            color: #dc3545;
            background: none;
            opacity: 1;
            border-radius: 20px;
            padding: 10px 0;
          }

          button:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }

  .total {
    align-self: flex-end;
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      margin-bottom: 40px;

      .title {
        color: rgba(0, 0, 0, 0.7);
        font-weight: 500;
        margin-right: 20px;
      }
    }

    button {
      width: 80%;
      align-self: flex-end;
    }
  }
}