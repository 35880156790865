@import 'variables';

@media screen and (min-width: 2000px) {
  * {
    font-size: 72.5%;
  }

  .main_good_container {
    width: $global_width_wide
  }

  header {
    width: $global_width_wide;

    nav {
      ul {
        li {
          font-size: 2rem;
        }
      }
    }
  }

  footer {
    padding: 0 calc((100vw - #{$global_width_wide}) / 2)
  }

  .subnavbar {
    width: $global_width_wide;
  }

  .good_subpage {
    width: $global_width_wide
  }

  .cart_wrapper {
    width: $global_width_wide;
  }
}

@media screen and (max-width: 1450px) {
  header {
    nav {
      ul {
        li {
          font-size: 2rem;
        }
      }
    }
  }

  .good_container {
    .good_image {
      flex: 2;

      img {
        width: 90%;
      }
    }

    .good_choice_wrapper {
      .good_choice {
        width: 95%
      }
    }
  }
}

@media screen and (max-width: 1250px) {
  * {
    font-size: 55%;
  }

  header {
    nav {
      ul {
        li {
          font-size: 1.8rem;
        }
      }
    }
  }

  .cart_wrapper {
    .cart {
      .cart_item {
        .cart_item_desc_container {
          .desc {
            flex: 3;
          }
        }
      }
    }
  }

  .subnavbar {
    ul {
      li {
        font-size: 2rem;
      }
    }
  }
}

@media screen and (max-width: 1000px) {
  * {
    font-size: 45%
  }

  button {
    font-size: 2.5rem;
  }

  p {
    font-size: 2rem;
  }

  header {
    nav {
      flex: 3;

      ul {
        li {
          font-size: 2.2rem;
        }
      }
    }
  }

  .good_container {
    flex-direction: column;
    height: auto;

    .good_image {
      height: auto;
      width: 100%;
      flex: 1;
      padding: 0;
      margin-bottom: 50px;
    }

    .good_choice_wrapper {
      flex: 1;
      width: 90%;
      justify-content: flex-start;

      .good_choice {
        width: 100%;
        height: auto;

        h4 {
          font-size: 3rem;
        }

        .color_selector_wrapper {
          width: 100%;
          flex-direction: row;
          align-items: center;

          h4 {
            color: rgba(black, 0.7);
            margin-bottom: 0;
          }

          .color_selector {
            margin-left: 20px;
            align-items: center;
          }
        }

        .model_selector {
          .models_wrapper {
            flex-direction: row;
            justify-content: center;

            h4 {
              margin-bottom: 0;
              color: rgba(black, 0.7);
              margin-right: 30px;
            }

            .models {
              div {
                font-size: 3rem;
              }
            }
          }

          ul {
            li {
              padding: 4px 0;
              font-size: 2rem;
            }
          }
        }

        .price_wrapper {
          margin-top: 50px;
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          button {
            margin-top: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 850px) {
  header {
    .burger_container {
      display: block;
    }

    .logo_container {
      justify-content: center;
    }

    nav {
      display: none;
    }
  }

  .main_good_container {
    flex-direction: column;
    margin-bottom: 50px;

    .main_good_description {
      height: 50%;
      width: 70%;
      order: 2;
      justify-content: center;

      .desc_container {
        align-self: flex-start;
        width: 100%;

        ul {
          li {
            font-size: 2rem;
          }
        }

        .desc-buttons {

          button {
            font-size: 3rem
          }
        }
      }
    }

    .main_good_image {
      width: 80%;
      order: 1;
      justify-content: center;
    }

    .right {
      justify-content: center;
    }
  }

  #overview {
    margin: 150px auto;
  }
  .overview_page {
    flex-direction: column;
    margin: 0 auto 150px;

    .overview_desc_container {
      order: 2;
      width: 100%;
      margin-top: 30px;
      justify-content: center;

      .overview_page_desc {
        h1 {
          margin-bottom: 5px;
        }

        h2 {
          margin-bottom: 5px;
        }

        h3 {
          margin-bottom: 15px;
        }
      }
    }

    .overview_desc_container.right {
      justify-content: center;
    }

    .overview_page_image {
      order: 1;
      justify-content: center;

      img {
        width: 80%;
      }
    }
  }

  .specs_container {
    margin: 70px 0;

    h2 {
      margin-bottom: 50px;
    }

    .specs_row {
      flex-direction: column;
      padding: 20px 20px 40px 0;

      h4 {
        align-self: flex-start;
        margin-bottom: 20px;
        color: rgba(black, 0.6);
        text-decoration: underline;
      }

      .specs_details {
        width: 100%;
        justify-content: space-around;

        p {
        }
      }
    }
  }

  .cart_wrapper {

    .cart {
      .cart_item {
        flex-direction: column;
        height: auto;

        .cart_item_image {
          display: none;
        }

        .cart_item_desc_container {
          padding-left: 0;
        }
      }
    }

    .total {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      div {
        flex: 1;
        margin-bottom: 0;
      }

      button {
        width: auto;
        align-self: center;
        height: 5rem;
        font-size: 2.5rem;
      }
    }
  }

  .swiper-button-prev,.swiper-button-next {
    display: none !important;
  }

  footer.safari {
    opacity: 0;
    z-index: -1;
    margin-top: 20px;
  }
}

@media screen and (max-width: 600px) {
  * {
    font-size: 35%;
  }

  #overview {
    margin: 100px auto;
  }
  .overview_page {
    margin: 0 auto 100px;
  }
  .subnavbar {
    height: $subheader_height*0.8;
    ul {
      li {
        padding: 5px;
      }

      .buy {
        margin-left: 5px;
      }
    }
  }

  .main_good_container {
    max-height: 80vh;
    .main_good_description {
      .desc_container {
        .desc-buttons {
          button {
            padding: 3px 10px;
            font-size: 2.5rem;
          }
        }
      }
    }
    .main_good_image{
      align-items: center;
      height: auto;
      flex: inherit;
    }
  }
}

@media screen and (max-width: 500px) {
  .good_container {
    .good_choice_wrapper {
      .good_choice {
        .color_selector_wrapper {
          flex-direction: column;
          align-items: flex-start;

          h4 {
            margin-bottom: 10px;
          }

          .color_selector {
            margin-left: 0;
          }
        }

        .model_selector {
          .models_wrapper {
            flex-direction: column;
            h4 {
              margin-bottom: 20px;
            }
          }

        }
      }
    }
  }

  .parallax_container {
    overflow: auto;
  }

  .parallax_layer {
    display: none;
  }

  .parallax_layer_front {
    display: block;
  }
}
