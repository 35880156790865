.parallax_container {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  perspective: 1px;
  perspective-origin: center;
}

.parallax_layer {
  position: absolute;
  top:0;
  left:0;
  right:0;
  bottom:0;
  opacity: 0.4;
  z-index: -1;
}

.parallax_layer_small {
  img {
    width:16vw;
    position: absolute;
  }
  transform: translateZ(-0.5px) scale(1.5);
}

.parallax_layer_medium {
  img {
    width:24vw;
    position: absolute;
  }
  transform: translateZ(-1px) scale(2);
}

.parallax_layer_big {
  img {
    width:36vw;
    position: absolute;
  }
  transform: translateZ(-2px) scale(3);
}

.parallax_layer_front {
  transform: translateZ(0);
  opacity: 1;
  z-index: 0;
}