@import '../variables';

$height: $good_height - 10vh;

#overview {
  margin: 250px auto;
}

.overview_page.last {
  margin-bottom: 0;
}

.overview_page {
  width: 100%;
  margin: 0 auto 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .overview_desc_container {
    height: 100%;
    display: flex;
    flex: 1;

    .overview_page_desc {
      display: flex;
      max-width: 80%;
      flex-direction: column;
      justify-content: center;

      h1 {
        margin-bottom: 20px;
      }

      h2 {
        margin-bottom: 10px;
      }

      h3 {
        margin-bottom: 30px;
        opacity: 0.8;
      }
    }
  }
  .overview_desc_container.right {
    justify-content: flex-end;
  }

  .overview_page_image {
    flex: 1;
    display: flex;
    align-items: center;
    height: 100%;

    img {
      width: 100%;
      object-fit: contain;
    }
  }
}