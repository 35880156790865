@import '../variables';

.good_container {
  padding: 0 !important;
  margin: 50px auto !important;
  display: flex;
  align-items: center;


  .good_image {
    flex: 1;
    overflow-x: hidden;
    display: flex;
    justify-self: center;
    justify-content: center;
    height: 100%;
    align-items: center;
    padding: 0 50px;

    img {
      object-fit: contain;
      width: 90%;
      max-width: 650px;
      max-height: 450px;
    }
  }

  .good_choice_wrapper {
    flex: 1;
    display: flex;
    justify-content: flex-end;

    .good_choice {
      flex-direction: column;
      height: 100%;
      display: flex;
      width: 62%;
      align-items: flex-start;
      justify-content: center;

      h3 {
        margin-bottom: 30px;
      }

      h4 {
        margin-bottom: 20px;
      }

      .color_selector_wrapper {
        margin-bottom: 30px;
        width: 80%;
        display: flex;
        flex-direction: column;

        .color_selector {
          height: 50px;
          display: flex;
          justify-content: flex-start;

          div {
            height: 40px;
            width: 40px;
            margin-right: 20px;
            border-radius: 50%;
            transition: all .3s;
            cursor: pointer;
            border: 2px solid transparent;
          }

          div:hover {
            transform: scale(1.1);
          }

          div.selected {
            border: 2px solid rgba(0, 0, 0, 0.5)
          }
        }
      }


      .model_selector {
        .models_wrapper {
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;

          .models {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            div {
              font-size: 2rem;
              font-weight: 500;
              margin-right: 40px;
              cursor: pointer;
              transition: all .3s;
            }

            div:hover {
              color: rgba($button_color, 0.8);
            }

            div.selected {
              color: rgba($button_color, 0.8);
            }
          }
        }


        ul {
          li {
            padding: 4px 0;
          }
        }
      }

      .price_wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .price {
          color: rgba(0, 0, 0, 0.7);
          margin-bottom:0
        }

        button {
          margin-top: 20px;
          border: none;
          background: $button_color;
          border-radius: 20rem;
          font-size: 2rem;
          font-weight: 500;
          color: white;
          opacity: 1;
        }
        button:hover {
          opacity: 0.8;
          border-radius: 20rem;
        }
      }
    }
  }
}


.swiper-button-prev,.swiper-button-next {
  color: #aaa !important;
  opacity: 0.6 !important;
  transition: all .3s;
}

.swiper-button-prev:hover {
  color: #007aff !important;
}

.swiper-button-next:hover {
  color: #007aff !important;
}

.swiper-pagination {
  opacity: 0.4 !important;
}